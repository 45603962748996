@import '../../../theme/global-color.scss';
#fill-in-blanks-2-wrapper {
  margin-top: 6rem;
  .input {
    padding: 0.4rem;
    max-width: 10rem;
    font-size: 1.6rem;
    margin-right: 0.8rem;
    margin-left: 0.8rem;
    border-radius: 0.4rem;
    border: 1px solid black;
    text-align: center;
  }
  .input.true {
    border: 2px solid var(--light-green) !important;
  }
  .input.false {
    border: 2px solid red !important;
  }

  .button {
    display: flex;
    justify-content: center;
    margin-top: 6rem;
    .check-btn {
      font-size: 1.6rem;
      color: black;
      background-color: var(--primary-600);
    }
  }
  .submit-btn {
    width: 30%;
  }
}

@media (max-width: 46.1875em) {
  #fill-in-blanks-2-wrapper {
    .submit-btn {
      width: 100%;
      margin: 0 12px;
    }
  }
}

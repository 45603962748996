.css-r8u8y9,
.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0 !important;
}
.css-1km1ehz,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  padding: 12px 16px !important;
}

.user-name {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

@import '../../../theme/global-color.scss';

#combobox1-wrapper {
  .question {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1.6rem;
    position: relative;
    .subtitle {
      position: absolute;
      top: -0.6rem;
      font-size: 1.2rem;
      line-height: 0.8rem;
    }
    .css-qiwgdb {
      padding: 1.2rem;
    }
    .combo-box {
      margin-left: 4rem;
      margin-right: 2rem;
      width: 30%;
      border-radius: 0.4rem;
      background-image: linear-gradient(#fff 10%, #c2eaf1 60%);
      .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
        padding: 1rem;
      }
    }
    .combo-box.true {
      background-image: linear-gradient(#fff, var(--light-green) 100%);
    }
    .combo-box.false {
      background-image: linear-gradient(#fff, red 100%);
    }
    .image {
      max-width: 80px;
      max-height: 80px;
    }
  }
  .button {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    .check-btn {
      font-size: 1.6rem;
      color: black;
      background-color: var(--primary-600);
    }
  }
  .submit-btn {
    width: 30%;
  }
}

@media (max-width: 46.1875em) {
  #combobox1-wrapper {
    .question {
      .combo-box {
        width: 13rem;
        border-radius: 0.4rem;
        background-image: linear-gradient(#fff 10%, #c2eaf1 60%);
        .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
          padding: 0.6rem;
          font-size: 1.35rem;
        }
      }
    }
    .submit-btn {
      width: 100%;
      margin: 0 12px;
    }
  }
}

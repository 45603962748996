@import '../../../theme/global-color.scss';
.fill-in-number-wrapper {
  display: flex;
  flex-direction: column;
  .css-1eed5fa-MuiInputBase-root-MuiInput-root::after {
    border-bottom: 2px solid var(--primary-900);
  }
  .css-1eed5fa-MuiInputBase-root-MuiInput-root {
    color: inherit;
  }
  .css-jcincl::after {
    border-bottom: 2px solid var(--primary-900);
  }
  .css-jcincl {
    color: inherit;
  }
  .css-mnn31 {
    font-size: 1.6rem;
    padding: 0;
    text-align: center;
  }
  .css-1x51dt5-MuiInputBase-input-MuiInput-input {
    margin-top: 0.05rem;
    font-size: 1.6rem;
    padding: 0;
    text-align: center;
  }
  .question-7-wrapper {
    margin-top: 1.2rem;
    .question-key {
      border-radius: 0.4rem;
      width: fit-content;
      padding: 0.6rem 0.6rem 0 0.6rem;
      background-color: var(--primary-500);
    }
    .question-key.false {
      background-color: red;
    }
    .question-key.true {
      background-color: var(--light-green);
    }
  }
  .textfield-word.true .css-1eed5fa-MuiInputBase-root-MuiInput-root {
    color: var(--light-green);
    font-weight: 500;
  }
  .textfield-word.false .css-1eed5fa-MuiInputBase-root-MuiInput-root {
    color: red;
  }
  .button {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
  }
  .submit-btn {
    width: 30%;
  }
}

@media (max-width: 46.1875em) {
  .fill-in-number-wrapper {
    .submit-btn {
      width: 100%;
      margin: 0 12px;
    }
  }
}

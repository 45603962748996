#forgot-password-wrapper {
  min-height: calc(100vh - 84px - 65px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4.8rem;
  .form-wrapper {
    font-size: 1.6rem;
    margin-top: 2.8rem;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    width: 50%;
    border: 1px solid #ccc;
    .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
      font-size: 1.6rem;
    }
    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
      font-size: 1.6rem;
    }

    .css-ywtyjy-MuiButtonBase-root-MuiButton-root:hover {
      background-color: #ffeb3b;
    }
  }
  .css-1uvydh2,
  .css-1x5jdmq {
    font-size: 1.6rem !important;
  }
  .css-1ig1k2d:hover {
    background-color: #ffeb3b !important;
  }
  .alert {
    margin-top: 2rem;
    width: 100%;
    font-size: 1.4rem;
  }
  .nsm7Bb-HzV7m-LgbsSe-BPrWId {
    font-size: 1.6rem !important;
    color: black;
  }
}

// Tablet
@media (min-width: 46.25em) and (max-width: 73.9375em) {
  #forgot-password-wrapper {
    .form-wrapper {
      width: 70%;
    }
  }
}

// Mobile
@media (max-width: 46.1875em) {
  #forgot-password-wrapper {
    padding: 3.2rem 1.2rem;
    .form-wrapper {
      padding: 2rem;
      width: 100%;
    }
  }
}

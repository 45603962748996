.title-input {
  font-size: 1.6rem;
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 1.6rem !important;
}
.file-input {
  font-size: 1.4rem !important;
}
.image-container {
  width: 400px;
  height: 240px;
  background-color: #ccc;
}
.css-1x5jdmq {
  font-size: 1.6rem !important;
}

:root {
  --primary-50: #fffde7;
  --primary-100: #fff9c4;
  --primary-200: #fff59d;
  --primary-300: #fff176;
  --primary-400: #ffee58;
  --primary-500: #ffeb3b;
  --primary-600: #fdd835;
  --primary-700: #fbc02d;
  --primary-800: #f9a825;
  --primary-900: #f57f17;

  --light-green: #09ef71;
  --light-blue: #0ac7f1;
}

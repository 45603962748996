.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  padding: 20px !important;
}

@media (min-width: 60em) {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    min-width: 500px !important;
  }
}

@media (max-width: 46.1875em) {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    padding: 0.8rem !important;
    min-width: 290px !important;
  }
}

@import '../../../theme/global-color.scss';

#choose-multiple-wrapper {
  .word-item {
    border-radius: 1rem;
    margin: 0.8rem;
    padding: 1.2rem 1.2rem 1.2rem 3.2rem;
    display: flex;
    justify-content: space-evenly;
    background-color: #d4e6f5;
    height: 12rem;
    .checkbox {
      background-color: #fff;
      // flex: 0 0 10%;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      margin-right: 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #068cfa;
    }
    .text-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 0 0 40%;
      max-width: 40%;
      .pinyin {
        display: block;
        font-size: 1.2rem;
      }
      .text {
        display: block;
        margin-top: -2.8rem;
      }
      .meaning {
        margin-top: 0.4rem;
      }
    }
    .image-wrapper {
      flex: 0 0 40%;
      max-width: 40%;
      z-index: 9;
      .image {
        max-width: 100px;
        max-height: 100px;
      }
    }
  }
  .word-item.false,
  .word-item.true {
    background-color: #bbdefb;
  }
  .word-item.false {
    border: 2px solid red;
  }
  .word-item.true {
    border: 2px solid var(--light-green);
  }
  .button {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
  }
  .submit-btn {
    width: 30%;
  }
}
// Mobile
@media (max-width: 46.1875em) {
  #choose-multiple-wrapper {
    .word-item {
      .text-wrapper {
        .pinyin {
          margin-bottom: 0.8rem;
        }
      }
      .image-wrapper {
        .image {
          max-width: 8rem;
          max-height: 8rem;
        }
      }
    }
    .submit-btn {
      width: 100%;
      margin: 0 12px;
    }
  }
}

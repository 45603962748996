@import '../../theme/global-color.scss';
#book-detail-wrapper {
  min-height: calc(100vh - 7.2rem - 66px - 12px);
  padding: 2.4rem 4rem;
  .back-link {
    color: #000;
    text-decoration: none;
    font-size: 1.4rem;
  }
  .back-link:hover {
    opacity: 0.8;
    text-decoration: underline;
    cursor: pointer;
  }
  .css-ttwr4n {
    display: none;
  }
  .css-1q2h7u5 {
    padding: 0;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    padding: 0;
  }
  .css-1aquho2-MuiTabs-indicator {
    display: none;
  }
  .book-list {
    margin-top: 2rem;
    border-radius: 1rem 1rem 0 0;
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    border-bottom: none;
    padding: 1.6rem;
    .Mui-focusVisible {
      background-color: #000;
    }
    .Mui-selected {
      .thumbnail-container {
        background-color: var(--primary-100);
        padding: 0.8rem;
        margin-right: 1.2rem;
        margin-left: 1.2rem;
        .thumbnail {
          display: block;
          height: 9rem;
          width: 7rem;
          object-fit: cover;
        }
        .title {
          display: block;
          width: 14rem;
          .book-title {
            margin-top: 0.4rem;
            font-size: 1.6rem;
            display: flex;
            justify-content: center;
            color: #000;
          }
        }
      }
    }
    .thumbnail-container {
      .thumbnail {
        display: block;
        height: 9rem;
        width: 7rem;
        object-fit: cover;
      }
      .title {
        display: none;
      }
    }
  }
  .css-19kzrtu {
    padding: 0;
  }
  .topic-list {
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
    border-top: none;
    border-bottom: none;
    cursor: pointer;
    .topic-item {
      display: flex;
      align-items: center;
      padding: 1.2rem 2.8rem;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
      .topic-icon {
        font-size: 2rem;
        margin-right: 2rem;
        color: rgba($color: #000000, $alpha: 0.7);
      }
    }
    .clicked {
      background-color: rgba($color: #000000, $alpha: 0.05);
    }
    .topic-item:hover {
      background-color: rgba($color: #000000, $alpha: 0.1);
    }
    .part-item {
      display: flex;
      align-items: center;
      padding: 1.2rem 6.4rem;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
      .part-icon {
        font-size: 2rem;
        margin-right: 2rem;
        color: rgba($color: #000, $alpha: 0.5);
      }
    }
    .part-item:hover {
      background-color: rgba($color: #000000, $alpha: 0.05);
    }
    .skill-item {
      display: flex;
      align-items: center;
      padding: 1.2rem 10rem;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
      .skill-icon {
        font-size: 2rem;
        margin-right: 2rem;
        color: rgba($color: #000000, $alpha: 0.7);
      }
    }
    .clicked {
      background-color: rgba($color: #000000, $alpha: 0.05);
    }
    .skill-item.odd {
      background-color: var(--primary-100);
    }
    .skill-item.even {
      background-color: var(--primary-50);
    }
    .skill-item:hover {
      background-color: rgba($color: #000000, $alpha: 0.1);
    }
  }
}
// 110%
@media (min-width: 80em) and (max-width: 90em) {
  #book-detail-wrapper {
  }
}
// 125%
@media (min-width: 70em) and (max-width: 80em) {
  #book-detail-wrapper {
  }
}
// Tablet
@media (min-width: 46.25em) and (max-width: 73.9375em) {
  #book-detail-wrapper {
    padding: 1.6rem 2rem;
  }
}
// Mobile
@media (max-width: 46.1875em) {
  #book-detail-wrapper {
    padding: 1.6rem 1.2rem;
    .book-list {
      padding: 0.8rem;
      .Mui-selected {
        .thumbnail-container {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
    .topic-list {
      .topic-item {
        padding: 1.2rem 1.2rem;
        .topic-icon {
          margin-right: 0.8rem;
        }
      }
      .part-item {
        padding: 1.2rem 2.8rem;
        .part-icon {
          margin-right: 0.8rem;
        }
      }
      .skill-item {
        padding: 1.2rem 4.4rem;
        .skill-icon {
          margin-right: 0.8rem;
        }
      }
    }
  }
}
// Smaller mobile
@media (max-width: 25.1875em) {
  #book-detail-wrapper {
  }
}

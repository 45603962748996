@import '../../theme/global-color.scss';
.book-item-container {
  cursor: pointer;
  width: 22rem;
  height: 38rem;
  margin-bottom: 3.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .book-item-details {
    min-height: 80px;
    padding: 0 5px;
    margin-top: 2rem;
    text-align: center;
    .book-item-time {
      font-size: 11px;
      font-style: italic;
      color: rgba(83, 83, 83, 0.7);
      margin-top: 5px;
    }
    .book-name {
      text-transform: uppercase;
      color: #000;
      font-weight: bold;
    }
  }
  .thumbnail {
    display: flex;
    justify-content: center;
  }
}
.book-item-container:hover {
  .thumbnail {
    animation: pulse-animation 2s cubic-bezier(0.24, 0, 0.38, 1) infinite;
    box-shadow: 5px 9px rgba($color: #000000, $alpha: 0.7);
    margin-bottom: 12px;
    filter: brightness(1.15);
  }
}
@keyframes pulse-animation {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.05);
    opacity: 0.9;
  }
}

// Mobile
@media (max-width: 46.1875em) {
  .book-item-container {
    margin-bottom: -2rem;
    .thumbnail {
      width: 18rem !important;
      height: 24rem !important;
    }
  }
}

.post-content {
  td,
  th {
    border: 1px solid black;
    padding: 10px;
  }
  table {
    padding: 0 2em 1em 0;
    border-collapse: collapse;
    border-spacing: 10px;
  }
}

@import '../../../theme/global-color.scss';

#group-wrapper {
  margin-top: 2.4rem;
  padding: 2rem 2rem 2rem 2rem;
  width: 80%;
  // background-image: linear-gradient(#fff, #c6c8f4, #fff);
  .group-container {
    border-radius: 0.4rem;
    background-color: #bce5fb;
    padding: 2rem;
  }
  .option-list-table {
    background-color: '#fff';
    th {
      font-size: 2rem !important;
    }
    .option-item {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 2rem !important;
    }
    .box {
      cursor: pointer;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    .check-btn {
      font-size: 1.6rem;
      color: black;
      background-color: var(--primary-600);
    }
  }
  .submit-btn {
    width: 30%;
  }
}
@media (min-width: 46.25em) and (max-width: 73.9375em) {
  .content-container {
    padding: 0 !important;
    #group-wrapper {
      padding: 0;
      width: 100%;
      .option-list-table {
        th {
          font-size: 2rem !important;
        }
        .option-item {
          font-size: 1.6rem !important;
        }
      }
    }
  }
}
// Mobile
@media (max-width: 46.1875em) {
  .content-container {
    padding: 0 !important;
    width: 100% !important;
    #group-wrapper {
      padding: 0;
      width: 100%;
      .group-container {
        padding: 1.2rem;
      }
      .option-list-table {
        th {
          font-size: 1.6rem !important;
        }
        .option-item {
          font-size: 1.3rem !important;
        }
      }
      .question {
        .subtitle {
          top: 0.4rem;
        }
        .question-content {
          margin-top: 1.2rem;
        }
        .image {
          display: none;
        }
      }
    }
    .submit-btn {
      width: 100%;
      margin: 0 12px;
    }
  }
}

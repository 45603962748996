#contact-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 99;
  .phone-btn,
  .email-btn,
  .zalo-btn,
  .message-btn {
    position: relative;
    margin-top: 4px;
    .contact-link {
      position: relative;
      z-index: 3;
      .contact-info {
        position: absolute;
        text-align: center;
        right: 55%;
        top: 7px;
        font-size: 18px;
        font-weight: 700;
        height: 35px;
        line-height: 35px;
        color: #fff;
        z-index: -1;
        border-radius: 25px;
        perspective: 1000px;
        transform-origin: right;
        transform: scaleX(0);
        transition: all 600ms cubic-bezier(0.04, 0.94, 0.21, 1.22);
      }
    }
    @keyframes contact-pulse-animation {
      0% {
        transform: scale(1);
        opacity: 0.6;
      }
      40% {
        transform: scale(1.3);
        opacity: 0.6;
      }
      100% {
        transform: scale(2);
        opacity: 0;
      }
    }
    .contact-link:after {
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 50%;
      animation: contact-pulse-animation 1.5s cubic-bezier(0.24, 0, 0.38, 1) infinite;
      -webkit-animation: contact-pulse-animation 1.5s cubic-bezier(0.24, 0, 0.38, 1) infinite;
      z-index: -1;
      opacity: 0.6;
    }
  }
  .phone-btn:hover,
  .email-btn:hover,
  .zalo-btn:hover,
  .message-btn:hover {
    .contact-info {
      min-width: 260px;
      transform: scaleX(1);
      overflow-x: hidden;
    }
  }
  .phone-btn {
    .contact-link:after,
    .contact-info {
      background-color: #f1052a;
    }
  }
  .email-btn {
    .contact-link:after,
    .contact-info {
      background-color: #f57b06;
    }
  }
  .zalo-btn {
    .contact-link {
      margin-bottom: -4px;
      .zalo-logo {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        box-shadow: -2px 0px 8px -3px black;
      }
      .contact-info {
        background-color: #005be0;
      }
    }
    .contact-link:after {
      background-color: #005be0;
    }
  }
  .message-btn {
    .contact-link:after,
    .contact-info {
      background-color: #2093e9;
    }
  }
}

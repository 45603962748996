@import '../../../theme/global-color.scss';
#choose2-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 1rem;
  padding: 3.2rem 2rem 2rem 2rem;
  .grid-item {
    padding: 0.4rem 0.4rem 0 0.4rem;
    margin-bottom: 5.2rem;
    display: flex;
    position: relative;
    .ordinal-number {
      position: absolute;
    }
    .ordinal-number.not-choose {
      color: red;
    }
    .audio {
      flex: 0 0 40%;
      max-width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
      .speaker.clicked {
        animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
      }

      @keyframes pulse {
        0%,
        100% {
          opacity: 1;
        }
        50% {
          opacity: 0.5;
        }
      }
    }

    .option-list {
      width: 100%;
      .option-item {
        cursor: pointer;
        border-radius: 0.8rem;
        padding: 1rem 0.4rem;
        border: 2px solid #7fa356;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-image: linear-gradient(#fff 10%, #c2eaf1 60%);
      }
      .option-item.clicked {
        background-image: linear-gradient(#fff, #09aaef 100%);
      }
      .option-item.true {
        background-image: linear-gradient(#fff, var(--light-green) 100%);
      }
      .option-item.false {
        background-image: linear-gradient(#fff, red 100%);
      }
    }
  }
  .button {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  .submit-btn {
    width: 30%;
  }
}

@media (max-width: 46.1875em) {
  #choose2-wrapper {
    padding: 0;
    .submit-btn {
      width: 100%;
      margin: 0 12px;
    }
  }
}

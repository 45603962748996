@import '../../../theme/global-color.scss';
#choose1-wrapper {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 4rem;
  .grid-item {
    padding: 0.8rem 0.8rem 0.4rem 0.8rem;
    border: 1px solid rgba(0, 0, 0, 0.3);
    display: flex;
    position: relative;
    .ordinal-number {
      position: absolute;
    }
    .ordinal-number.not-choose {
      color: red;
    }
    .audio {
      flex: 0 0 40%;
      max-width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
      .speaker.clicked {
        animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
      }

      @keyframes pulse {
        0%,
        100% {
          opacity: 1;
        }
        50% {
          opacity: 0.5;
        }
      }
    }

    .option-list {
      flex: 0 0 60%;
      max-width: 60%;
      .option-item {
        cursor: pointer;
        border-radius: 0.8rem;
        padding: 0.4rem;
        border: 2px solid #7fa356;
        text-align: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 0.4rem;
        background-image: linear-gradient(#fff 10%, #c2eaf1 60%);
      }
      .option-item.clicked {
        background-image: linear-gradient(#fff, #09aaef 100%);
      }
      .option-item.true {
        background-image: linear-gradient(#fff, var(--light-green) 100%);
      }
      .option-item.false {
        background-image: linear-gradient(#fff, red 100%);
      }
    }
  }
  .button {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
  }
  .submit-btn {
    width: 30%;
  }
}

@media (max-width: 46.1875em) {
  #choose1-wrapper {
    .submit-btn {
      width: 100%;
      margin: 0 12px;
    }
  }
}

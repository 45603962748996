@import '../../../theme/global-color.scss';

.match-wrapper {
  display: flex;
  flex-direction: column;
  .css-1eed5fa-MuiInputBase-root-MuiInput-root::after {
    border-bottom: 2px solid var(--primary-900);
  }
  .css-jcincl::after {
    border-bottom: 2px solid var(--primary-900);
  }
  .css-mnn31 {
    font-size: 1.6rem;
    padding: 0;
  }
  .css-1x51dt5-MuiInputBase-input-MuiInput-input {
    margin-top: 0.05rem;
    font-size: 1.6rem;
    padding: 0;
    // text-align: center;
    // color: var(--primary-900);
  }
  .question-4-wrapper {
    display: flex;
    .question-4 {
      margin-left: 2.8rem;
      .question-key.false {
        color: red;
        font-style: italic;
      }
      .question-key.true {
        color: var(--light-green);
        font-weight: bold;
      }
    }
  }
  .button {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
  }
  .submit-btn {
    width: 30%;
  }
}

// Mobile
@media (max-width: 46.1875em) {
  .match-wrapper {
    .question-4-wrapper {
      .question-4 {
        margin-left: 2.4rem;
        .content-column-1 {
          margin-top: 0;
        }
        .content-column-2 {
          margin: 0 4rem 0 -2rem;
        }
        .content-column-3 {
          margin-top: 0;
        }
      }
    }
    .submit-btn {
      width: 100%;
      margin: 0 12px;
    }
  }
}

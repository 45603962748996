@import '../../../theme/global-color.scss';

.fill-in-blanks-wrapper {
  display: flex;
  flex-direction: column;
  .css-1eed5fa-MuiInputBase-root-MuiInput-root::after {
    border-bottom: 2px solid var(--primary-900);
  }
  .css-jcincl::after {
    border-bottom: 2px solid var(--primary-900);
  }
  .css-mnn31 {
    font-size: 1.6rem;
    padding: 0;
    text-align: center;
  }
  .css-1x51dt5-MuiInputBase-input-MuiInput-input {
    margin-top: 0.05rem;
    font-size: 1.6rem;
    padding: 0;
    text-align: center;
  }

  .textfield.true .css-1eed5fa-MuiInputBase-root-MuiInput-root {
    color: var(--light-green);
    font-weight: 500;
  }
  .textfield.false .css-1eed5fa-MuiInputBase-root-MuiInput-root {
    color: red;
  }
  .question-5-wrapper {
    margin-top: 2rem;
    .question-5 {
      display: flex;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
  }
  .submit-btn {
    width: 30%;
  }
}

// Mobile
@media (max-width: 46.1875em) {
  .fill-in-blanks-wrapper {
    .question-5-wrapper {
      margin-top: 0;
      .question-5 {
        margin-left: -1.2rem;
        margin-right: 1.2rem;
      }
    }
    .submit-btn {
      width: 100%;
      margin: 0 12px;
    }
  }
}

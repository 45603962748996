@import '../../theme/global-color.scss';
.profile-wrapper {
  min-height: calc(100vh - 84px - 65px);
  padding: 5rem;
  color: black;
  .page-title {
    text-align: center;
    font-size: 3.2rem;
    margin-bottom: 4.4rem;
  }

  .form-container {
    width: fit-content;
    margin: auto;
  }

  .input {
    .css-1x5jdmq,
    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 1rem 1.4rem !important;
    }
    margin-bottom: 2.4rem;
    width: fit-content;
    display: grid;
    grid-template-columns: 280px 1fr;
    gap: 2rem;
    .label {
      font-size: 1.4rem;
    }
    .outline-input {
      width: 500px;
    }
  }
  .avatar-container {
    display: flex;
    flex-direction: column;
    width: 500px;
  }
}

// Tablet and mobile
@media (max-width: 63.9375em) {
  .profile-wrapper {
    padding: 3rem 5rem;

    .input {
      width: 100%;
      grid-template-columns: 1fr;
      .outline-input {
        width: 100%;
      }
      .label {
        margin-bottom: -1rem;
      }
    }
    .form-container {
      width: 100% !important;
    }
    .avatar-container {
      width: 100% !important;
    }
  }
}

// Mobile
@media (max-width: 46.1875em) {
  .profile-wrapper {
    padding: 3rem 2rem;
  }
}
